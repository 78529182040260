.container {
  padding: 20px 25px;
  padding-top: 0;
  margin-top: auto;

  display: flex;
  align-items: center;
  gap: 10px;
}

.form {
  display: flex;

  flex-direction: row;
  align-items: flex-end;
  gap: 10px;

  width: 100%;

  @media (max-width: 720px) {
    padding: 0px 5px;
  }
}

.form__item {
  margin: 0 !important;
}

.form__item_input_container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form__item_input_file_preview {
  padding-top: 10px;

  display: flex;
  gap: 10px;
}

.file_preview__icon svg {
  fill: #00000073;
}

.popoverContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.popoverContent p {
  margin-bottom: 0 !important;
}
